@use '../../../styles/vars';

.app {

    text-align: center;
    overflow-x: hidden;
    font-family: Lato, sans-serif; //Tahoma, Geneva, Kalimati, sans-serif;

}

.recrutement {
    padding-top: 0px;
    width: 100%;
    height: 95vh;
    background-color: #0B132B;
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    align-items: baseline;
    margin:auto;
    align-items: center;
    justify-content: center;
    &-text {
        font-size: smaller;
        width: 85%;
        text-shadow:
        0 0 7px #5BC0BE,
        0 0 10px #6FFFE9,
    }
    &-title {
        text-shadow:
        0 0 7px #5BC0BE,
        0 0 10px #6FFFE9,
        0 0 21px #6FFFE9,
    }
}