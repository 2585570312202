@use '../../../styles/vars';

.app {

    text-align: center;
    overflow-x: hidden;
    font-family: Lato, sans-serif; //Tahoma, Geneva, Kalimati, sans-serif;

}

.histoire {
    padding-top: 0px;
    width: 100%;
    height: 95vh;
    background-color: #0B132B;
    display: flex;
    //align-items: center;
    //justify-content: center;
    flex-direction: row;
    vertical-align: middle;
    align-items: baseline;
    margin:auto;
    align-items: center;
    &-oldschool, &-newschool {
        &-text {
            &-title {
                text-shadow:
                    0 0 7px #5BC0BE,
                    0 0 10px #6FFFE9,
                    0 0 21px #6FFFE9;
            }

            &-block {
                max-width:80%;
                margin:auto;
                margin-bottom:50px;
                text-shadow:
                    0 0 7px #5BC0BE,
                    0 0 10px #6FFFE9,
                    0 0 21px #6FFFE9;
            }
            
        }
        &-img {
            width:300px;
            height:300px;
            background-color: #6FFFE9;
            //border: 5px solid #6FFFE9;
            box-shadow: 1px 0px 15px 0px #6FFFE9, 0px 0px 30px 0px #6FFFE9;
            margin:auto;
            margin-bottom:50px;
        }
    }
/*
    &-newschool {
        &-text {
            &-title {
                text-shadow:
                    0 0 7px #5BC0BE,
                    0 0 10px #6FFFE9,
                    0 0 21px #6FFFE9;
            }

            &-block {
                max-width:80%;
                margin:auto;
                margin-bottom:20px;
                text-shadow:
                    0 0 7px #5BC0BE,
                    0 0 10px #6FFFE9,
                    0 0 21px #6FFFE9;
            }
        }
    }
    */
}
/*
.temporaire {
    background-color: rgb(172, 0, 0);
    opacity: 50%;
    width: 480px;
    height: 280px;
    margin-left: auto;
    margin-right: auto;
}
*/