
@use '../../../styles/vars';
.app {
    
    text-align: center;
    overflow-x:hidden;
    font-family: Lato,sans-serif;//Tahoma, Geneva, Kalimati, sans-serif;
    
}
.la404 {
    padding-top: 64px;
    width:100%;
    height:90vh;
    background-color: #1C2541;
    &-title {
        color:#fff;
        text-shadow:
        0 0 7px #5BC0BE,
        0 0 10px #6FFFE9,
        0 0 21px #6FFFE9;
        font-size: 2000%;
    }
}