@use '../../../styles/vars';
.header {
  z-index:7;
  position: fixed;
  top: 0;
  width: 100%; 
  height: 64px;
  margin-right: 0%;
  margin-left:0%;
  align-items: baseline;
  background-color: #1C2541;
  vertical-align: middle;
  &-links {
    height:64px;
    width:70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    &-text {
      color:#fff;
    }
    &-text:link, &-text:visited, &-text:active, &-text:hover {
      color:#fff;
      text-decoration: none;
      cursor: pointer;
    }
    &-text:hover {

      text-shadow:
      0 0 7px #fff,
      0 0 10px #fff,
      0 0 21px #5BC0BE,
      0 0 42px #6FFFE9;
    }
  }
  
}
@media (max-width: 640px) {
  //
}