
@use '../../../styles/vars';
.app {
    
    text-align: center;
    overflow-x:hidden;
    font-family: Lato,sans-serif;//Tahoma, Geneva, Kalimati, sans-serif;
    
}
.home {
    padding-top: 0px;
    width:100%;
    height:95vh;
    background-color: #0B132B;
    display: flex;
    //align-items: center;
    justify-content: center;
    flex-direction: column;
    &-title {
        font-size: 400%;
        color:#fff;
        text-shadow:
        0 0 7px #5BC0BE,
        0 0 10px #6FFFE9,
        0 0 21px #6FFFE9,
    }
    &-about {
        color:#fff;
        text-shadow:
        0 0 7px #5BC0BE,
        0 0 10px #6FFFE9,
    }
}
.temporaire {
    background-color: rgb(172, 0, 0);
    opacity: 50%;
    width:480px;
    height:280px;
    margin-left:auto;
    margin-right: auto;
}