@use '../../../styles/vars';

.app {

    text-align: center;
    overflow-x: hidden;
    font-family: Lato, sans-serif; //Tahoma, Geneva, Kalimati, sans-serif;

}

.recrutement {
    padding-top: 0px;
    width: 100%;
    height: 95vh;
    background-color: #0B132B;
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    align-items: baseline;
    margin:auto;
    align-items: center;
    justify-content: center;
}